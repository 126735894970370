/* AWS */
import Amplify from '@aws-amplify/core';
import {Auth} from '@aws-amplify/auth';
import {AWSAppSyncClient} from "aws-appsync";
import App from './App.vue';
/* CSFrontendComponents */
import CSFrontendComponents from "@cs/cs-frontend-components";
import "@cs/cs-frontend-components/dist/CSFrontendComponents.css";
/* CSQuantengine */
import CSQuantengineFrontendComponents from "@cs/cs-quantengine-frontend";
import "@cs/cs-quantengine-frontend/dist/CSQuantengineFrontendComponents.css";
/* CSQuantengine2.0 */
import CSQuantengineFrontendComponents2 from "@cs/csquantengine-frontend";
import "@cs/csquantengine-frontend/dist/CSQuantengine.css";
import StringFormatter
    from "@cs/cs-quantengine-frontend/src/js-helpers/stringFormatter";
/* CSDistributionService */
import CSDistributionServiceFrontendComponents
    from "@cs/cs-distributionservice-frontend";
import "@cs/cs-distributionservice-frontend/dist/CSDistributionServiceFrontendComponents.css";
/* CSLegal */
import CSLegalFrontendComponents
    from "@cs/cs-legal-frontend/src/csLegalFrontendPlugin";
import "@cs/cs-legal-frontend/dist/CSLegalFrontendComponents.css";
import CSIcons from "@cs/cs-legal-frontend/src/js/CsIcons";
/* CSDataWarehouse */
import CSDataWarehouseFrontendComponents
    from "@cs/cs-datawarehouse-frontend/src/csDataWarehousePlugin";
import "@cs/cs-datawarehouse-frontend/dist/CSDataWarehouse.css";
/* CSResearch */
import CSResearchHostingFrontendComponents
    from "@cs/cs-research-frontend/src/csResearchHostingPlugin";
import "@cs/cs-research-frontend/dist/CSResearch.css";
/* CSChronicles */
import CSChroniclesFrontendComponents
    from "@cs/cschronicles-frontend/src/csChroniclesFrontendPlugin";
import "@cs/cschronicles-frontend/dist/CSChroniclesFrontendComponents.css";
/* Project internal files */
import CSBaseLogger from './js/csbase-logger';
import PermissionHelper from "./js/permissions/permissionHelper";
import router from './router';
import store from './store';
/* Other */
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-pro/css/all.css';
/* Vue related imports */
import FlagIcon from 'vue-flag-icon';
import Notifications from "vue-notification";
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import VueI18n from 'vue-i18n';
import Vuetify from './plugins/vuetify';

let BASE_CLIENT;
let BASE_REALTIME_CLIENT;

fetch("config.json").then((json) => {
    json.json().then(async (parameters) => {
        Vue.prototype.$BACKEND_PARAMETERS = parameters;

        // const cookieStorage = new CookieStorage({
        //     secure: false,
        //     domain: parameters.BaseDomain || 'localhost',
        //     sameSite: "lax",
        //     expires: 365,
        //     path: "/"
        // });

        const awsmobile = {
            "aws_project_region": "eu-central-1",
            "aws_appsync_graphqlEndpoint": [parameters.AppSyncURL],
            "aws_appsync_region": "eu-central-1",
            "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
            "aws_cognito_region": "eu-central-1",
            "aws_user_pools_id": parameters.CognitoUserPoolID,
            "aws_user_pools_web_client_id": parameters.CognitoClientID,
            "oauth": {},
            // "cookieStorage": cookieStorage,
            // "storage": cookieStorage,
        };
        Amplify.configure(awsmobile);
        Auth.configure(awsmobile);
        /* Configure baseClient (AppSyncClient) */
        const config = {
            url: parameters.AppSyncURL,
            region: 'eu-central-1',
            auth: {
                type: 'AMAZON_COGNITO_USER_POOLS',
                jwtToken: async () => {
                    const currentSession = await Auth.currentSession();
                    return currentSession.getAccessToken().getJwtToken();
                }
            },
            disableOffline: true
        };
        const realtime_config = {
            url: parameters.AppSyncURL + '/realtime',
            region: 'eu-central-1',
            auth: {
                type: 'AMAZON_COGNITO_USER_POOLS',
                jwtToken: async () => {
                    const currentSession = await Auth.currentSession();
                    return currentSession.getAccessToken().getJwtToken();
                }
            },
            disableOffline: true
        };
        const options = {
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'cache-and-network',
                }
            }
        };

        // Creates the vue apollo provider
        BASE_CLIENT = new AWSAppSyncClient(config, options);
        BASE_REALTIME_CLIENT = new AWSAppSyncClient(realtime_config, options);
        let clients = {
            BASE_CLIENT: BASE_CLIENT,
            BASE_REALTIME_CLIENT: BASE_REALTIME_CLIENT
        };
        const appsyncProvider = new VueApollo({
            clients: clients,
            defaultClient: BASE_CLIENT,
        });
        /* Vue use objects */
        Vue.use(Amplify);
        Vue.config.productionTip = false;
        Vue.use(CSFrontendComponents, {store: store});
        Vue.use(CSBaseLogger);
        Vue.use(FlagIcon);
        Vue.use(Notifications);
        Vue.use(CSQuantengineFrontendComponents, {router});
        Vue.use(CSQuantengineFrontendComponents2, {router});
        Vue.use(CSDistributionServiceFrontendComponents, {router});
        Vue.use(CSLegalFrontendComponents, {router, store});
        Vue.use(CSDataWarehouseFrontendComponents, {router});
        Vue.use(CSResearchHostingFrontendComponents, {router});
        Vue.use(CSChroniclesFrontendComponents, {router});
        Vue.use(VueApollo);
        Vue.use(VueI18n);
        Vue.use(Vuetify);
        const i18n = new CSFrontendComponents.CSBasicsI18n(require('./lang.json'), store).i18n;
        Vue.prototype.$stringFormatter = StringFormatter;
        Vue.prototype.$csDialog = CSFrontendComponents.CSDialog;
        Vue.prototype.$awsAppSyncClientConfig = {config, options};
        Vue.prototype.$csIcons = CSIcons;
        Vue.prototype.$csBasePermissionHelper = PermissionHelper;
        new Vue({
            apolloProvider: appsyncProvider,
            i18n,
            vuetify: Vuetify,
            router,
            store,
            render: h => h(App)
        }).$mount('#app');
    });
});
// Needed for global access to Apollo Client
export {BASE_CLIENT};
