import { render, staticRenderFns } from "./EditRole.vue?vue&type=template&id=12ff9bf2&scoped=true&"
import script from "./EditRole.vue?vue&type=script&lang=js&"
export * from "./EditRole.vue?vue&type=script&lang=js&"
import style0 from "./EditRole.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./EditRole.vue?vue&type=style&index=1&id=12ff9bf2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ff9bf2",
  null
  
)

/* custom blocks */
import block0 from "./lang.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc1418574028%2Fsrc%2Fsrc%2Fviews%2Fcsbase-role-management%2FEditRole.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports